import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, output } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { ConfirmLevel } from '@@core/models/confirmation-level';

@Component({
	selector: 'sl-confirmation-box',
	templateUrl: './confirmation-box.component.html',
	styleUrl: './confirmation-box.component.scss',
	standalone: true,
	imports: [
		CommonModule,
		MatTooltipModule,
		MatIconModule,
		MatButtonModule,
		ReactiveFormsModule,
		InputTextareaModule,
		MatDialogModule
	]
})
export class ConfirmationBoxComponent implements OnInit {
	readonly delete = output<ConfirmLevel>();
	readonly cancel = output<ConfirmLevel>();

	title: string | null = null;
	okText = 'Unignore';
	reasonControl = new FormControl<string>('', Validators.required);
	isReasonNeeded = false;

	readonly data = inject(MAT_DIALOG_DATA) as { title: string; message: string; isReasonNeeded: boolean; okText: string };
	readonly #dialogRef = inject(MatDialogRef) as MatDialogRef<ConfirmationBoxComponent>;

	ngOnInit(): void {
		if (this.data.okText) {
			this.okText = this.data.okText;
		}
		this.isReasonNeeded = this.data.isReasonNeeded;
		this.title = this.data.title;
	}

	isOkEnabled(): boolean {
		if (!this.isReasonNeeded) {
			return true;
		}
		return this.reasonControl.valid;
	}

	confirmDelete(): void {
		if (this.isReasonNeeded) {
			this.#dialogRef.close({ level: ConfirmLevel.Delete, reason: this.reasonControl.value });
		} else {
			this.#dialogRef.close(ConfirmLevel.Delete);
			this.delete.emit(ConfirmLevel.Delete);
		}
	}

	cancelAction(): void {
		if (this.isReasonNeeded) {
			this.#dialogRef.close({ level: ConfirmLevel.Cancel });
		} else {
			this.#dialogRef.close(ConfirmLevel.Cancel);
			this.cancel.emit(ConfirmLevel.Cancel);
		}
	}
}
