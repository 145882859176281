import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { ConfirmationDialogData } from '../../models/confirmation-dialog.model';

@Component({
	selector: 'sl-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrl: './confirmation-dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		MatButtonModule,
		MatIconModule,
		MatDialogModule,
	]
})
export class ConfirmationDialogComponent implements OnInit {
	readonly defaultDialogData: ConfirmationDialogData = {
		title: '',
		content: 'Are you sure?',
		cancelButtonCaption: 'No',
		confirmButtonCaption: 'Yes',
		showCancelButton: true,
		confirmButtonIcon: null,
		confirmButtonTheme: 'primary'
	};

	mergedData: ConfirmationDialogData;

	readonly data = inject(MAT_DIALOG_DATA) as ConfirmationDialogData;
	readonly #dialogRef = inject(MatDialogRef) as MatDialogRef<ConfirmationDialogComponent>;

	ngOnInit(): void {
		this.mergedData = { ...this.defaultDialogData, ...this.data };
	}

	cancel(): void {
		this.#dialogRef.close(false);
	}

	confirm(): void {
		this.#dialogRef.close(true);
	}
}
