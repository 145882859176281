import { CommonModule } from '@angular/common';
import { Component, Input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'sl-dialog',
	templateUrl: './dialog.component.html',
	styleUrl: './dialog.component.scss',
	standalone: true,
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		MatDialogModule
	]
})
export class DialogComponent {
	@Input() title: string;
	@Input() styleClass: string;
	@Input() customClose = false;
	@Input() justifyActionsToStart = false;

	readonly closeClicked = output<any>();

	onCloseClick(): void {
		this.closeClicked.emit(null);
	}
}
