<div mat-dialog-title class="sl-flexbox-row">
	<div class="dialog-header sl-flexbox-row sl-flexbox-align-center sl-flexbox-justify-space-between">
		<div class="sl-flexbox-row sl-flexbox-gap-20 sl-flexbox-justify-start sl-flexbox-align-center">
			<div class="sl-flexbox-column">
				<p class="sl-typography-title-large dialog-title {{ styleClass }}">{{ title }}</p>
				<ng-content select="[sub-header]"></ng-content>
			</div>
		</div>
		<span class="material-symbols-outlined mat-icon-24 cursor-pointer" *ngIf="customClose"  (click)="onCloseClick()">close</span>
		<span class="material-symbols-outlined mat-icon-24 cursor-pointer" *ngIf="!customClose" [mat-dialog-close]="">close</span>
	</div>
</div>

<div mat-dialog-content class="sl-flexbox-column sl-flexbox-gap-50">
	<div class="">
		<div class="column">
			<ng-content select="[content]"></ng-content>
		</div>
	</div>
</div>

<div class="hide-when-empty" mat-dialog-actions [class.justify-content-start]="justifyActionsToStart">
	<ng-content select="[actions]"></ng-content>
</div>
