<sl-dialog [title]="mergedData.title" [styleClass]="mergedData.titleStyleClass">
	<ng-container content>
		<div class="sl-flexbox-gap-16 sl-typography-body {{ mergedData.contentStyleClass }}" [innerHTML]="mergedData.content"></div>
	</ng-container>
	<ng-container actions>
		<button *ngIf="mergedData.showCancelButton && mergedData.cancelButtonCaption?.length"
				[mat-dialog-close]="false"
				class="sl-typography-link-action"
				mat-button>
			{{ mergedData.cancelButtonCaption }}
		</button>
		<button class="new-design"
				[mat-dialog-close]="true"
				mat-button>
			{{ mergedData.confirmButtonCaption }}
		</button>
	</ng-container>
</sl-dialog>
