<div class="confirm-box sl-flexbox-column sl-flexbox-justify-center sl-flexbox-align-start sl-flexbox-gap-20">
	<div *ngIf="title" mat-dialog-title>
		<div class="sl-typography-title ellipsisize sl-width-auto" [matTooltip]="title">{{ title }}</div>
	</div>
	<div class="confirm-box-content sl-flexbox-row sl-flexbox-justify-start sl-flexbox-align-center sl-flexbox-gap-10">
		<mat-icon>error_outline</mat-icon>
		<div class="wrapper sl-flexbox-column sl-flexbox-justify-start sl-flexbox-align-start sl-flexbox-flex-dynamic sl-typography-body">
			<div>{{ data.message }}</div>
			<div>Are you sure?</div>
		</div>
	</div>
	<textarea *ngIf="isReasonNeeded" pInputTextarea row="1" class="new-design" placeholder="Reason" [autoResize]="true" [formControl]="reasonControl"></textarea>
	<div class="confirm-box-actions sl-flexbox-row sl-flexbox-self-align-end sl-flexbox-gap-10">
		<button mat-button type="button" (click)="cancelAction()">Cancel</button>
		<button mat-button type="button" class="primary-button" [disabled]="!isOkEnabled()" (click)="confirmDelete()">{{ okText }}</button>
	</div>
</div>
