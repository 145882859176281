<sl-dialog [title]="config.title" class="alert-modal">
	<ng-container content>
		<div class="sl-flexbox-column sl-flexbox-gap-16">
			<p [innerHTML]="config.message"></p>
			<div *ngIf="config.showInput" class="sl-flexbox-column sl-flexbox-gap-8">
				<label *ngIf="config.inputLabel" class="input-label uppercase sl-typography-label" for="input-value">{{ config.inputLabel }}</label>
				<input id="input-value"
						pInputText
						type="text"
						[placeholder]="config?.inputPlaceholder || ''"
						(input)="onInputChange()"
						[(ngModel)]="inputValue"
						autocorrect="off"
						spellcheck="false"
						autocomplete="off"
						[attr.maxlength]="config?.inputLimitationLength || 4196">
				<span *ngIf="error" class="sl-typography-body-medium error">{{ error }}</span>
			</div>
		</div>
	</ng-container>
	<ng-container actions>
		<button *ngIf="config.cancelButtonCaption?.length"
				[mat-dialog-close]="true"
				class="sl-typography-link-action"
				mat-button
				[disabled]="isLoading">
			{{ config.cancelButtonCaption }}
		</button>
		<button class="new-design confirm-button"
				[disabled]="isLoading || (config?.showInput && !inputValue)"
				[mat-dialog-close]="config.closeOnConfirm"
				mat-button
				(click)="onConfirm()">
			{{ config.confirmButtonCaption }}
		</button>
	</ng-container>
</sl-dialog>
