<div mat-dialog-title class="confirmation-dialog-headersl-flexbox-row sl-flexbox-justify-space-between sl-flexbox-align-center">
	<ng-container [ngSwitch]="mergedData?.type">
		<mat-icon *ngSwitchCase="'success'" class="success">check_circle</mat-icon>
		<mat-icon *ngSwitchCase="'info'" class="info">info</mat-icon>
		<mat-icon *ngSwitchCase="'warning'" class="warning">warning</mat-icon>
		<mat-icon *ngSwitchCase="'error'" class="error">error</mat-icon>
	</ng-container>
	<span class="dialog-title">{{ mergedData.title }}</span>
</div>
<div mat-dialog-content class="confirmation-dialog-content">
	<div class="content-wrapper">
		<div class="message" [innerHtml]="mergedData.content"></div>
	</div>
</div>
<div mat-dialog-actions class="confirmation-dialog-actions sl-flexbox-row sl-flexbox-justify-end sl-flexbox-align-center">
	<button *ngIf="mergedData.showCancelButton" type="button" class="sl-typography-link-action" mat-button (click)="cancel()">{{ mergedData.cancelButtonCaption }}</button>
	<button type="button" class="new-design primary-button" mat-button (click)="confirm()">
		<mat-icon *ngIf="mergedData.confirmButtonIcon" class="button-icon">{{ mergedData.confirmButtonIcon }}</mat-icon>
		{{ mergedData.confirmButtonCaption }}
	</button>
</div>
